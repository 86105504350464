import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../store/actions';
import Notifications from './Notifications/Notifications';
import MyAccount from './EventMenuDropdowns/MyAccount';
import {
	// getNotReadChatMessages,
	getSortedNotifications,
	handleModularCoOrganizerMenu,
	preventDefaultDrag,
	getNotReadChatMessages,
} from '../Utils/utils';
import { EXHIBITOR_TYPES } from '../Utils/constants/shared';

import '../CSS/headerMenu.scss';
import '../CSS/leftSidebar.scss';
import {
	connectToEvent,
	disconnectFromEvent,
	eventUsersNewParticipant,
	eventUsersNewExhibitor,
	eventUsersRemovedParticipant,
	eventUsersRemovedExhibitor,
	userEventRolesUpdate,
	eventUsersUpdatedExhibitor,
	eventUsersUpdatedParticipant,
	eventUsersUpdatedOrganizer,
	socketEventSettingUpdated,
	connectOrganizerToEvent,
	handleUserStatusUpdate,
	eventMatching,
	eventDeleted,
	socketCustomRegFieldsUpdated,
	deregisterSocketCustomRegFieldsUpdated,
} from '../Api/socketApi';
import Button from '@material-ui/core/Button';
import SMPopup from '.././Dialogs/SMPopup';
import DetectOutsideClick from '../HOC/DetectOutsideClick';
// import MyEvents from './EventMenuDropdowns/MyEvents';
import MyBooths from './EventMenuDropdowns/MyBooths';
import EventMenuMobile from './EventMenu/EventMenuMobile';
// import BackButton from './EventMenu/BackButton';
import EventLanguage from './EventMenuDropdowns/EventLanguage';
import '../CSS/headerMenu.scss';
import colors from '../CSS/_variables.module.scss';
// import {ReactComponent as MenuDropdownIcon} from '../Images/svg/menu-dropdown-icon.svg';
import { ReactComponent as NotificationsIcon } from '../Images/svg/ring.svg';
import { ReactComponent as NotificationsFilled } from '../Images/svg/NotificationsFilled.svg';
import { ReactComponent as LogoImage } from '../Images/svg/logo.svg';
import { ReactComponent as Share } from '../Images/svg/sm-share.svg';
// import {ReactComponent as CoOrganizerEmpty} from '../Images/svg/co-organizer-empty.svg';
// import CoOrganizerFilled from '../Images/svg/CoOrganizerFilled';
import omit from 'lodash/omit';
import AuditoriumHeaderLink from './EventMenu/AuditoriumHeaderLink';
import ShowfloorHeaderLink from './EventMenu/Rooms/ShowfloorHeaderLink';
import throttle from 'lodash/throttle';
import axios from '../store/axios-instance';

class EventMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			notReadChatMessages: 0,
			active: false,
			sharePopup: false,
			openLeaveVideoMeetingDialog: false,
			showRemovedFromEventDialog: false,
			usersStatusToBeUpdated: [],
		};
		// throttle is used when the online users is constantly changing (from socket events)
		this.throttleUserStatusUpdates = throttle(
			this.updateOnlineUsersInStore,
			5000
		);
	}

	componentDidMount() {
		const { event, eventId, eventSlug, user } = this.props;
		const userEventRolesObj = user.eventRoles.find(
			(role) => role.event.slug === eventSlug
		);
		// using this one instead of eventRoles.isOrganizer because that one comes delayed
		const isOrganizer = userEventRolesObj.roles.includes('organizer');
		// only for pages that are in event
		// add a class to app-container <div>
		// to force the screen to be full height
		document.getElementById('app-container').classList.add('event-page');
		connectToEvent(user._id, event._id);
		// listen to any changes of the eventSettings
		socketEventSettingUpdated((err, settingData) =>
			this.props.onEventSettingUpdated(settingData)
		);
		// listen to changes of custom fields
		socketCustomRegFieldsUpdated((err, data) =>
			this.props.onReloadUserProfile()
		);
		// make sure we listen to the socket event when a new user registers to the event
		eventUsersNewParticipant((err, data) =>
			this.props.onAddEventParticipant(data.participant)
		);
		eventUsersNewExhibitor((err, data) =>
			this.props.onAddEventExhibitor(data.exhibitor)
		);
		// make sure we listen to the socket event when a user unregisters from the event
		eventUsersRemovedParticipant((err, data) =>
			this.props.onRemoveEventParticipant(data)
		);
		eventUsersRemovedExhibitor((err, data) =>
			this.props.onRemoveEventExhibitor(data)
		);
		// make sure we listen to the socket event when a user updates his data from myAccount
		eventUsersUpdatedOrganizer((err, data) =>
			this.props.onUpdateEventOrganizer(data.organizer)
		);
		eventUsersUpdatedParticipant((err, data) =>
			this.props.onUpdateEventParticipant(data.participant)
		);
		eventUsersUpdatedExhibitor((err, data) =>
			this.props.onUpdateEventExhibitor(data.exhibitor)
		);
		// after fetching the getUsers api we initialize the following socket which
		// returns the user id & status everytime a user gets ONLINE || goes OFFLINE
		// and add / remove the userId from the onlineUsers array
		handleUserStatusUpdate((data) => this.handleUpdateOnlineUsersInStore(data));
		// # ONLY FOR ORGANIZER - (get extra data as organizer)
		// in order to see this requires connection to connectOrganizerToEvent()
		// which is done in componentDidUpdate() for organizers only

		// updates user eventRoles each time the organizer changes something in the Co-Organizer
		userEventRolesUpdate((err, { eventRoles }) =>
			this.props.onUpdateUserEventRoles(eventRoles)
		);
		// # END - ONLY FOR ORGANIZER
		// we only want to set the platform language just after the user entered the event
		const defaultPlatformLanguage = user.language;
		this.props.onSetPlatformLanguage(defaultPlatformLanguage);
		this.setUserEventRoles();
		this.setUserEventAccess();
		this.calculateNotReadChatMessages();
		// get event Archive
		this.getEventArchive();
		// Matching enabled / disabled socket listener will be in event.enableMatching
		eventMatching((err, data) => this.props.onSetEnableMatching(data.value));
		// if user is organizer when logging in or refreshing page
		if (isOrganizer) {
			connectOrganizerToEvent(user._id, eventId);
		}

		// GET the current logged in user his event profile
		this.props.onGetUserEventProfile(eventId, user._id);

		this.props.listFloors(eventId);
	}

	componentDidUpdate(prevProps) {
		const { event, eventId, eventSlug, eventRoles, user } = this.props;
		// Co-Organizer active status check
		const userEventRolesObj = user?.eventRoles?.find(
			(role) => role.event.slug === eventSlug
		);
		// using this one instead of eventRoles.isOrganizer because that one comes delayed
		const isOrganizer = userEventRolesObj?.roles?.includes('organizer');
		const isCoOrganizerActive = userEventRolesObj?.accessRights?.isActive;
		// connect isOrganizer to the socket then get withRoles information once
		// then update array through the connected socket...
		// // //
		// first prev !== current check is for when changing event
		// last prev !== current check is for when changing event from any other role to
		// an event where you are organizer
		if (
			prevProps.eventId !== eventId &&
			!prevProps.eventRoles.isOrganizer &&
			isOrganizer &&
			!(
				prevProps.eventId === eventId &&
				!prevProps.eventRoles.isOrganizer &&
				isOrganizer
			)
		) {
			connectOrganizerToEvent(user._id, eventId);
		}
		// first prev !== current && isOrganizer check is for when changing event from organizer to organizer
		// last 2 prev !== current check are to prevent running this if a second time if the one
		// above already runned once

		if (
			prevProps.eventId !== eventId &&
			isOrganizer &&
			!(
				prevProps.eventId !== eventId &&
				!prevProps.eventRoles.isOrganizer &&
				isOrganizer
			) &&
			!(
				prevProps.eventId === eventId &&
				!prevProps.eventRoles.isOrganizer &&
				isOrganizer
			)
		) {
			connectOrganizerToEvent(user._id, eventId);
		}
		// connect isCoOrganizer to the socket then get withRoles information once
		// then update array through the connected socket
		// first prev !== current checks for if eventRoles for isCoOrganizer, isCoOrganizerActive or eventId changed
		// last prev !== current check is for checking if the CoOrganizer was previously NOT active
		// and became active. isCoOrganizerActive makes sure the CoOrganizer is currently active
		if (
			(prevProps.eventRoles.isCoOrganizer !== eventRoles.isCoOrganizer ||
				prevProps.eventRoles.isCoOrganizerActive !==
					eventRoles.isCoOrganizerActive ||
				(prevProps.eventId !== eventId && eventRoles.isCoOrganizerActive)) &&
			isCoOrganizerActive
		) {
			connectOrganizerToEvent(user._id, eventId);
			// if when co-organizer becomes active and has access to auditoriums
			// getEvent in order to have the auditorium dailyProgram populated
			if (userEventRolesObj?.accessRights?.auditoriums)
				this.props.onGetEvent(eventId);
		}
		// END - ORGANIZER / CO-ORGANIZER

		// when current user object changes, double check its roles
		if (prevProps.user !== user) {
			this.setUserEventRoles();
		}

		if (prevProps.event && prevProps.event._id !== event._id && user) {
			this.setUserEventRoles();
			this.setUserEventAccess();
			// GET the current logged in user his event profile
			this.props.onGetUserEventProfile(eventId, user._id);
		}

		if (
			(prevProps.user && prevProps.user.privateChats !== user.privateChats) ||
			prevProps.user.groupChats !== user.groupChats
		) {
			this.calculateNotReadChatMessages();
		}

		if (prevProps.event._id !== event._id) {
			disconnectFromEvent(user._id, prevProps.event._id);
			connectToEvent(user._id, event._id);
			// get event Archive
			this.getEventArchive();
		}
		// if (prevProps.event.name !== event.name) {
		// disconnectFromEvent(user._id, prevProps.event._id);
		// connectToEvent(user._id, event._id);
		// get event Archive
		// this.getEventArchive();
		// }
		if (
			prevProps.event._id !== event._id &&
			prevProps.eventRoles.isCoOrganizer
		) {
			const { eventSlug } = this.props;
			this.props.history.push(`/event/${eventSlug}/lobby`);
			this.timeoutId = setTimeout(() => {
				this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
			}, 50);
		}
	}

	componentWillUnmount() {
		const { event, user } = this.props;
		disconnectFromEvent(user._id, event._id);
		deregisterSocketCustomRegFieldsUpdated();
		// remove the class that forced the screen to be full height on event pages
		document.getElementById('app-container').classList.remove('event-page');
		clearTimeout(this.timeoutId);
	}

	updateOnlineUsersInStore = () => {
		const { usersStatusToBeUpdated } = this.state;
		this.props.onSetOnlineUsers(usersStatusToBeUpdated);
		this.setState({
			usersStatusToBeUpdated: [],
		});
	};

	// usersStatusToBeUpdated is an array where we keep
	// all the users that have changed the online status
	// until we actually call the function to update them in the store
	handleUpdateOnlineUsersInStore = ({ id, status }) => {
		const { usersStatusToBeUpdated } = this.state;
		const user = { id, status };

		this.setState(
			{
				usersStatusToBeUpdated: [...usersStatusToBeUpdated, user],
			},
			() => {
				this.throttleUserStatusUpdates();
			}
		);
	};

	openSharePopup = () => this.setState({ sharePopup: true });
	closeSharePopup = () => this.setState({ sharePopup: false });

	refreshPage = () => {
		axios
			.delete(`users/${this.props.user._id}/remove-user-from-redis`)
			.then((data) => {
				window.location.reload(true);
			})
			.catch((error) => {
				window.location.reload(true);
			});
	};

	isActive = () =>
		this.setState({
			active: true,
		});

	getNotReadNotifications = () => {
		const filteredNotifications = getSortedNotifications(
			this.props.user.notifications
		);
		return filteredNotifications.filter(
			(notification) => notification.seenAt === null
		).length;
	};

	checkExhibitorsCanLogin = () => {
		const { exhibitorData, event } = this.props;
		let canLogin = true;
		if (exhibitorData?.type === EXHIBITOR_TYPES.company) {
			canLogin = event.exhibitorsLogin;
		}
		return canLogin;
	};

	checkScholarCanLogin = () => {
		const { exhibitorData, event } = this.props;
		let canLogin = true;
		if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
			canLogin = event.scholarsLogin;
		}
		return canLogin;
	};

	checkisScholar = () => {
		const { exhibitorData } = this.props;
		let isScholar = false;
		if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
			isScholar = true;
		}
		return isScholar;
	};

	setUserEventAccess = () => {
		const resourcesAccess = this.getUserEventAccess();
		this.props.onSetEventAccess(resourcesAccess);
	};

	calculateNotReadChatMessages = () => {
		let privateChats = [...this.props.user.privateChats];
		let groupChats = [...this.props.user.groupChats];
		let totalMessagesUnread = getNotReadChatMessages(privateChats, groupChats);
		this.props.onSetNotReadChatMessages(totalMessagesUnread);
	};

	getEventArchive = () => {
		const { eventId } = this.props;
		const resourcesAccess = this.getUserEventAccess();
		let hasAccessToEventArchive = true;
		if (resourcesAccess && !resourcesAccess.archive) {
			hasAccessToEventArchive = false;
		}
		if (hasAccessToEventArchive) {
			this.props.onGetEventArchive(eventId);
		}
	};

	setUserEventRoles = () => {
		const { eventId, eventSlug, user } = this.props;
		if (eventId && user) {
			const userRolesInEvent = user.eventRoles.find(
				(role) => role.event.slug === eventSlug
			);
			const userIsExhibitor =
				userRolesInEvent && userRolesInEvent.roles.includes('exhibitor');
			const userIsExhibitorRep =
				userRolesInEvent && userRolesInEvent.roles.includes('exhibitorRep');
			const userIsParticipant =
				userRolesInEvent && userRolesInEvent.roles.includes('participant');
			const userIsOrganizer =
				userRolesInEvent && userRolesInEvent.roles.includes('organizer');
			const userIsOrganizerRep =
				userRolesInEvent && userRolesInEvent.roles.includes('organizerRep');
			const userIsCoOrganizer =
				userRolesInEvent && userRolesInEvent.roles.includes('coOrganizer');
			const userIsCoOrganizerActive = user?.eventRoles?.find(
				(role) => role.event.slug === eventSlug
			)?.accessRights?.isActive;
			// when you update the eventRoles here, please make sure that you also update
			// in userReducer the initialEventRoles to be exactly the same
			const eventRoles = {
				isOrganizer: userIsOrganizer,
				isCoOrganizer: userIsCoOrganizer,
				isCoOrganizerActive: userIsCoOrganizerActive || false,
				isOrganizerRep: userIsOrganizerRep,
				isExhibitor: userIsExhibitor,
				exhibitorId: userIsExhibitor ? userRolesInEvent.exhibitor._id : null,
				exhibitorType: userIsExhibitor ? userRolesInEvent.exhibitor.type : null,
				isExhibitorRep: userIsExhibitorRep,
				exhibitorRepIds: userIsExhibitorRep
					? userRolesInEvent.exhibitorRepresentative
					: [],
				isParticipant: userIsParticipant,
				participantId: userIsParticipant ? userRolesInEvent.participant : null,
			};
			this.props.onSetUserEventRoles(eventRoles);
		}
	};

	getUserEventAccess = () => {
		const { eventSlug, event, user } = this.props;
		const userRolesInEvent = user.eventRoles.find(
			(role) => role.event.slug === eventSlug
		);
		const userIsOrganizer =
			userRolesInEvent && userRolesInEvent.roles.includes('organizer');
		const userIsParticipant =
			userRolesInEvent && userRolesInEvent.roles.includes('participant');
		const userIsExhibitor =
			userRolesInEvent && userRolesInEvent.roles.includes('exhibitor');

		let resourcesAccess = null;

		let userIsCompanyExhibitor = false;
		let userIsScholarExhibitor = false;

		// if user is organizer, don't set up any resourcesAccess so that he has full access everywhere
		// if resourcesAccess is null, we don't have any access management on the front end
		if (userIsOrganizer) {
			return resourcesAccess;
		}

		if (userIsExhibitor) {
			userIsCompanyExhibitor =
				userRolesInEvent.exhibitor.type === EXHIBITOR_TYPES.company;
			userIsScholarExhibitor =
				userRolesInEvent.exhibitor.type === EXHIBITOR_TYPES.scholar;
		}

		// participant access
		if (event.hasAccessManagement && userIsParticipant) {
			const participantPackageId = userRolesInEvent.accessPackage;

			if (participantPackageId) {
				const participantAccessPackage = event.accessPackages.find(
					(accessPackage) => accessPackage._id === participantPackageId
				);
				resourcesAccess = participantAccessPackage.resourcesAccess;
			}
		}
		// exhibitor COMPANY access
		if (event.exhibitorHasAccessManagement && userIsCompanyExhibitor) {
			const exhibitorPackageId = userRolesInEvent.exhibitorAccessPackage;

			if (exhibitorPackageId) {
				const exhibitorAccessPackage = event.exhibitorAccessPackages.find(
					(exhibitorAccessPackage) =>
						exhibitorAccessPackage._id === exhibitorPackageId
				);
				resourcesAccess = exhibitorAccessPackage.resourcesAccess;
			}
		}

		// exhibitor SCHOLAR access
		if (event.scholarHasAccessManagement && userIsScholarExhibitor) {
			const exhibitorPackageId = userRolesInEvent.exhibitorAccessPackage;

			if (exhibitorPackageId) {
				const exhibitorAccessPackage = event.exhibitorAccessPackages.find(
					(exhibitorAccessPackage) =>
						exhibitorAccessPackage._id === exhibitorPackageId
				);
				resourcesAccess = exhibitorAccessPackage.resourcesAccess;
			}
		}
		return resourcesAccess;
	};

	handleEnterKey = (tab) => (e) => {
		if (e.key === 'Enter') {
			if (tab === 'booths') return this.props.seeMyBooths();
			if (tab === 'events') return this.props.seeMyEvents();
			if (tab === 'share') return this.openSharePopup();
			if (tab === 'notifications') return this.props.seeNotifications();
			if (tab === 'account') return this.props.seeMyAccount();
		}
	};

	// check if user is Co-Organizer, isActive and has at least 1 truthy accessRights option
	checkShowCoOrganizerMenu = () => {
		let count = 0;
		const { user, event, eventSlug, eventRoles } = this.props;
		const userEventRolesObj = user.eventRoles.find(
			(role) => role.event.slug === eventSlug
		);
		// dinamically omit certain accessRights booleans from object (loadash)
		const accessRightsWithoutSomeOptions = omit(
			userEventRolesObj?.accessRights,
			[
				'isActive',
				!event.sessionsStreaming ? 'sessions' : '',
				!(
					this.props.event.hasAccessManagement ||
					this.props.event.exhibitorHasAccessManagement ||
					this.props.event.scholarHasAccessManagement ||
					this.props.event.closedEvent
				)
					? 'accessManagement'
					: '',
			]
		);
		if (accessRightsWithoutSomeOptions !== undefined) {
			Object.values(accessRightsWithoutSomeOptions).forEach(
				(value) => value === true && count++
			);
		}

		return (
			eventRoles?.isCoOrganizer &&
			userEventRolesObj?.accessRights?.isActive &&
			count >= 1
		);
	};

	render() {
		const {
			eventSlug,
			eventRoles,
			isLargeScreen,
			translation,
			defaultTranslation,
			languages,
			event,
			serverDown,
			sessionExpired,
			topNavigation,
			user,
			seeMyBooths,
			location,
			seeNotifications,
			seeMyAccount,
		} = this.props;
		const { sharePopup } = this.state;
		const eventURL = `${window.location.origin}${
			event.slug ? '/event/' + event.slug : ''
		}`;
		let notificationsNotRead = this.getNotReadNotifications();
		let activeHome = location.pathname.indexOf('lobby') > -1 ? true : false;
		const activeMetaverseTab = location.pathname.indexOf('metaverse') > -1;
		const activeFloorPlanTab = location.pathname.indexOf('floorplan') > -1;
		let activeExhibitorDashboard =
			location.pathname.indexOf('exhibitor-dashboard') > -1 ? true : false;
		let activeExhibitorRepDashboard =
			location.pathname.indexOf('exhibitor-booth-view') > -1 ? true : false;
		let activeOrganizerDashboard =
			location.pathname.indexOf('/dashboard/') > -1 ? true : false;
		let activeMyAccount =
			location.pathname.indexOf('/my-account') > -1 ? true : false;
		const exhibitorCanLogin = this.checkExhibitorsCanLogin();
		const scholarCanLogin = this.checkScholarCanLogin();
		const isScholar = this.checkisScholar();
		const showCoOrganizerMenu = this.checkShowCoOrganizerMenu();
		// check which Co-Organizer accesRights are active and direct Co-Organizer to the
		// first active organizer dashboard tab
		let modularCoOrganizerLink = false;
		const userEventRolesObj = user.eventRoles.find(
			(role) => role.event.slug === eventSlug
		);
		if (userEventRolesObj?.accessRights) {
			modularCoOrganizerLink = handleModularCoOrganizerMenu(
				userEventRolesObj?.accessRights,
				event
			);
		}
		// if participant or exhibitor can NOT login, hide the EventMenuMobile
		if (!isLargeScreen) {
			return (!event.participantsLogin &&
				!eventRoles.isOrganizer &&
				!eventRoles.isExhibitor) ||
				(eventRoles.isExhibitor &&
					!eventRoles.isOrganizer &&
					!exhibitorCanLogin) ||
				(isScholar && !eventRoles.isOrganizer && !scholarCanLogin) ||
				activeMetaverseTab ||
				activeFloorPlanTab ? null : (
				<EventMenuMobile
					refreshPage={this.refreshPage}
					notificationsNotRead={notificationsNotRead}
					activeMyAccount={activeMyAccount}
					activeHome={activeHome}
				/>
			);
		}

		const filteredFloors = this.props.floors?.eventFloors?.data?.filter(
			(floor) => !floor.hidden
		);

		return (
			<div
				onDragStart={preventDefaultDrag}
				className={`top-container ${
					languages.platformLanguage === 'ch' ? 'ch' : ''
				}`}
			>
				{/* {serverDown && (
                    <div onDragStart={preventDefaultDrag} className="server-down-info-container ">
                        <p>
                            {translation?.eventMenu.connectionProblemTextFirst}
                            <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.refreshPage}>
                                {translation?.eventMenu.connectionProblemButton}
                            </span>
                            {translation?.eventMenu.connectionProblemTextSecond}
                        </p>
                    </div>
                )}*/}
				{sessionExpired && (
					<div
						onDragStart={preventDefaultDrag}
						className="server-down-info-container"
					>
						<p>
							{translation?.eventMenu.sessionProblemTextFirst}
							<span
								onDragStart={preventDefaultDrag}
								draggable="false"
								onClick={this.refreshPage}
							>
								{translation?.eventMenu.sessionProblemButton}
							</span>
							{translation?.eventMenu.sessionProblemTextSecond}
						</p>
					</div>
				)}
				<header id="header" className="header">
					<div onDragStart={preventDefaultDrag} className="main-menu-container">
						<div onDragStart={preventDefaultDrag} className="actions-container">
							<div
								onDragStart={preventDefaultDrag}
								className="left-header-wrapper"
							>
								<Link
									tabIndex="0"
									to={{ pathname: `/event/${eventSlug}/lobby` }}
								>
									<LogoImage />
								</Link>
								{/*<div*/}
								{/*    onDragStart={preventDefaultDrag}*/}
								{/*    id="my-events"*/}
								{/*    className="button-link"*/}
								{/*    onKeyDown={this.handleEnterKey('events')}*/}
								{/*>*/}
								{/*    <p onDragStart={preventDefaultDrag} onClick={seeMyEvents}>*/}
								{/*        <span>*/}
								{/*            <span*/}
								{/*                tabIndex="0"*/}
								{/*                className={`${topNavigation.seeMyEvents ? 'rotate-arrow' : ''} ${*/}
								{/*                    event.name.split(' ').length === 1 ? 'single-row' : ''*/}
								{/*                }`}*/}
								{/*            >*/}
								{/*                {event.name}*/}
								{/*                <MenuDropdownIcon fill={colors.secondary} />*/}
								{/*            </span>*/}
								{/*        </span>*/}
								{/*    </p>*/}
								{/*    {topNavigation.seeMyEvents && (*/}
								{/*        <DetectOutsideClick>*/}
								{/*            <MyEvents />*/}
								{/*        </DetectOutsideClick>*/}
								{/*    )}*/}
								{/*</div>*/}
							</div>
							<div
								onDragStart={preventDefaultDrag}
								className="main-header-wrapper"
							>
								{/*<BackButton />*/}
								{event ? (
									<>
										{user.isOrganizer && user._id === event.owner._id ? (
											<Link
												tabIndex="0"
												onDragStart={preventDefaultDrag}
												className={`button-link ${
													activeOrganizerDashboard ? 'active' : ''
												}`}
												to={{
													pathname: `/event/${eventSlug}/dashboard/settings`,
												}}
											>
												<p
													onDragStart={preventDefaultDrag}
													className="button-link-label"
												>
													{translation?.eventMenu.organizerButton}
												</p>
											</Link>
										) : null}
									</>
								) : null}
								{event && showCoOrganizerMenu && (
									<div tabIndex="-1" onDragStart={preventDefaultDrag}>
										<Link
											tabIndex="0"
											className={`button-link ${
												activeOrganizerDashboard ? 'active' : ''
											}`}
											to={{
												pathname: `/event/${eventSlug}/${
													modularCoOrganizerLink !== undefined
														? modularCoOrganizerLink
														: 'lobby'
												}`,
											}}
										>
											<p
												onDragStart={preventDefaultDrag}
												className="button-link-label"
											>
												{translation?.eventMenu.coOrganizerButton ||
													defaultTranslation?.eventMenu.coOrganizerButton}
											</p>
										</Link>
									</div>
								)}
								{eventRoles.isExhibitor ? (
									<Link
										tabIndex="0"
										className={
											'button-link exhibitor-dashboard-button ' +
											(activeExhibitorDashboard ? 'active' : '')
										}
										to={{ pathname: `/event/${eventSlug}/exhibitor-dashboard` }}
									>
										<p
											onDragStart={preventDefaultDrag}
											className="button-link-label"
										>
											{eventRoles.exhibitorType === 'scholar'
												? translation?.eventMenu.posterStudyButton ||
												  defaultTranslation?.eventMenu.posterStudyButton
												: translation?.eventMenu.exhibitorButton ||
												  defaultTranslation?.eventMenu.exhibitorButton}
										</p>
									</Link>
								) : null}
								{!eventRoles.isExhibitor &&
								eventRoles.isExhibitorRep &&
								eventRoles.exhibitorRepIds &&
								eventRoles.exhibitorRepIds.length === 1 ? (
									<Link
										tabIndex="0"
										className={
											'button-link exhibitor-dashboard-button ' +
											(activeExhibitorRepDashboard ? 'active' : '')
										}
										to={{
											pathname: `/event/${eventSlug}/exhibitor-booth-view/${eventRoles.exhibitorRepIds[0]}`,
										}}
									>
										<p
											onDragStart={preventDefaultDrag}
											className="button-link-label"
										>
											{translation?.eventMenu.boothButton}
										</p>
									</Link>
								) : null}
								{!eventRoles.isExhibitor &&
								eventRoles.isExhibitorRep &&
								eventRoles.exhibitorRepIds &&
								eventRoles.exhibitorRepIds.length > 1 ? (
									<div
										tabIndex="0"
										className={
											'button-link exhibitor-dashboard-button ' +
											(activeExhibitorRepDashboard || topNavigation.seeMyBooths
												? 'active'
												: '')
										}
										id="my-booths"
										onClick={seeMyBooths}
										onKeyDown={this.handleEnterKey('booths')}
									>
										<p
											onDragStart={preventDefaultDrag}
											className="button-link-label"
										>
											{translation?.eventMenu.boothsButton}
										</p>
										{topNavigation.seeMyBooths && (
											<DetectOutsideClick>
												<MyBooths />
											</DetectOutsideClick>
										)}
									</div>
								) : null}
								<Link
									tabIndex="0"
									onDragStart={preventDefaultDrag}
									className={
										'button-link home-button ' + (activeHome ? 'active' : '')
									}
									to={{ pathname: `/event/${eventSlug}/lobby` }}
								>
									<p
										onDragStart={preventDefaultDrag}
										className="button-link-label"
									>
										{translation?.eventMenu.homeButton}
									</p>
								</Link>
								<AuditoriumHeaderLink />
								<ShowfloorHeaderLink />
								{/* <Link
									tabIndex="0"
									onDragStart={preventDefaultDrag}
									className={`button-link home-button ${
										activeMetaverseTab ? 'active' : ''
									}`}
									to={{ pathname: `/event/${eventSlug}/metaverse` }}
								>
									<p
										onDragStart={preventDefaultDrag}
										className="button-link-label"
									>
										{translation?.eventMenu.metaverse ||
											defaultTranslation?.eventMenu.metaverse}
									</p>
								</Link> */}
								{filteredFloors?.length > 0 && (
									<Link
										tabIndex="0"
										onDragStart={preventDefaultDrag}
										className={`button-link home-button ${
											activeFloorPlanTab ? 'active' : ''
										}`}
										to={{ pathname: `/event/${eventSlug}/floorplan` }}
									>
										<p
											onDragStart={preventDefaultDrag}
											className="button-link-label"
										>
											{translation?.eventMenu.floorPlan ||
												defaultTranslation?.eventMenu.floorPlan}
										</p>
									</Link>
								)}
							</div>
							<div
								onDragStart={preventDefaultDrag}
								className="right-header-wrapper"
							>
								<EventLanguage />
								{false && isLargeScreen && <EventLanguage />}
								{process.env.REACT_APP_SM_SHARE === 'true' && (
									<Button
										tabIndex="0"
										type="button"
										classes={{ root: 'sm-share-button' }}
										onClick={this.openSharePopup}
										onKeyDown={this.handleEnterKey('share')}
										disableTouchRipple
										disableFocusRipple
										disableRipple
									>
										<Share fill={colors.black} className="sm-share-icon" />
									</Button>
								)}
								<div
									tabIndex="0"
									onKeyDown={this.handleEnterKey('notifications')}
									onDragStart={preventDefaultDrag}
									id="notifications"
									className={`button-link ${
										topNavigation.seeNotifications && 'not-read'
									}`}
								>
									<div
										onDragStart={preventDefaultDrag}
										onClick={seeNotifications}
									>
										{topNavigation.seeNotifications ? (
											<NotificationsFilled />
										) : (
											<NotificationsIcon
												width="22"
												height="22"
												fill={colors.black}
											/>
										)}
										{notificationsNotRead && (
											<div
												className={`not-read-notifications flex-center ${
													notificationsNotRead.toString().length >= 3
														? 'fs8'
														: 'fs11'
												}`}
											>
												<span>
													{notificationsNotRead.toString().length >= 3
														? '99+'
														: notificationsNotRead}
												</span>
											</div>
										)}
									</div>
									{topNavigation.seeNotifications && (
										<DetectOutsideClick>
											<Notifications />
										</DetectOutsideClick>
									)}
								</div>
								<div
									tabIndex="0"
									onKeyDown={this.handleEnterKey('account')}
									id="myaccount"
									className={`button-link ${
										topNavigation.seeMyAccount || activeMyAccount
											? 'active'
											: ''
									}`}
								>
									<div onDragStart={preventDefaultDrag} onClick={seeMyAccount}>
										<p
											onDragStart={preventDefaultDrag}
											className="button-link-label"
										>
											{translation?.eventMenu.accountButton ||
												defaultTranslation?.eventMenu.accountButton}
										</p>
									</div>
									{topNavigation.seeMyAccount && (
										<DetectOutsideClick>
											<MyAccount
												history={this.props.history}
												closeDropdown={seeMyAccount}
											/>
										</DetectOutsideClick>
									)}
								</div>
							</div>
						</div>
					</div>
				</header>
				<SMPopup
					open={sharePopup}
					closeConfirm={this.closeSharePopup}
					dialogTitle={
						translation?.socialMediaShare?.dialogTitleGeneral ||
						defaultTranslation?.socialMediaShare?.dialogTitleGeneral
					}
					eventName={event.name}
					popupLanguageLevel="platform"
					facebookQuote={`${
						translation?.socialMediaShare?.imAttending ||
						defaultTranslation?.socialMediaShare?.imAttending
					} ${event.name ? `"${event.name}"` : ''} ${
						translation?.socialMediaShare?.event ||
						defaultTranslation?.socialMediaShare?.event
					}. ${
						translation?.socialMediaShare?.interestedClick ||
						defaultTranslation?.socialMediaShare?.interestedClick
					}`}
					twitterMessage={`${
						translation?.socialMediaShare?.imAttending ||
						defaultTranslation?.socialMediaShare?.imAttending
					} ${event.name ? `"${event.name}"` : ''} ${
						translation?.socialMediaShare?.event ||
						defaultTranslation?.socialMediaShare?.event
					}. ${
						translation?.socialMediaShare?.interestedClick ||
						defaultTranslation?.socialMediaShare?.interestedClick
					}`}
					emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${event.name}`}
					emailBody={`${
						translation?.socialMediaShare?.imAttending ||
						defaultTranslation?.socialMediaShare?.imAttending
					} ${event.name ? `"${event.name}"` : ''} ${
						translation?.socialMediaShare?.event ||
						defaultTranslation?.socialMediaShare?.event
					}. ${
						translation?.socialMediaShare?.interestedClick ||
						defaultTranslation?.socialMediaShare?.interestedClick
					} ${eventURL}`}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
		eventSlug: state.event.eventSlug,
		eventUsers: state.eventUsers,
		event: state.event.data,
		eventArchive: state.eventArchive.data,
		user: state.user.data,
		eventRoles: state.user.eventRoles,
		exhibitorData: state.exhibitor.data,
		resourcesAccess: state.user.resourcesAccess,
		loggedIn: state.user.loggedIn,
		topNavigation: state.user.topNavigation,
		chatsDesktop: state.user.chatsDesktop,
		serverDown: state.user.serverDown,
		sessionExpired: state.user.sessionExpired,
		isLargeScreen: state.layout.isLargeScreen,
		languages: state.languages,
		translation: state.languages.translations[state.languages.platformLanguage],
		defaultTranslation: state.languages.translations['en'],
		videoConference: state.videoConference,
		eventUsersWithRoles: state.organizer.eventUsersWithRoles,
		showfloor: state.showfloors.showfloor,
		floors: state.floors,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		seeContacts: () => dispatch(actions.topNavSeeContacts()),
		seeProgram: () => dispatch(actions.topNavSeeProgram()),
		seeChats: () => dispatch(actions.topNavSeeChats()),
		seeMyEvents: () => dispatch(actions.topNavSeeMyEvents()),
		seeMyBooths: () => dispatch(actions.topNavSeeMyBooths()),
		seeNotifications: () => dispatch(actions.topNavSeeNotifications()),
		seeMyAccount: () => dispatch(actions.topNavSeeMyAccount()),
		onSetUserEventRoles: (exhibitorData) =>
			dispatch(actions.setUserEventRoles(exhibitorData)),
		onSetNotReadChatMessages: (numberOfNotReadMessages) =>
			dispatch(actions.setNotReadChatMessages(numberOfNotReadMessages)),
		onGetEventArchive: (eventId) => dispatch(actions.getEventArchive(eventId)),
		onSetEventAccess: (resourcesAccess) =>
			dispatch(actions.setEventAccess(resourcesAccess)),
		onSetPlatformLanguage: (language) =>
			dispatch(actions.setPlatformLanguage(language)),
		onAddEventParticipant: (participant) =>
			dispatch(actions.addEventParticipant(participant)),
		onAddEventExhibitor: (exhibitor) =>
			dispatch(actions.addEventExhibitor(exhibitor)),
		onRemoveEventParticipant: (participantId) =>
			dispatch(actions.removeEventParticipant(participantId)),
		onRemoveEventExhibitor: (exhibitorId) =>
			dispatch(actions.removeEventExhibitor(exhibitorId)),
		onUpdateEventParticipant: (participant) =>
			dispatch(actions.updateEventParticipant(participant)),
		onUpdateEventExhibitor: (exhibitor) =>
			dispatch(actions.updateEventExhibitor(exhibitor)),
		onUpdateEventOrganizer: (organizer) =>
			dispatch(actions.updateEventOrganizer(organizer)),
		onSetOnlineUsers: (user) => dispatch(actions.setOnlineUsers(user)),
		onEventSettingUpdated: (settingData) =>
			dispatch(actions.eventSettingUpdated(settingData)),
		onGetExhibitor: (exhibitorId) =>
			dispatch(actions.getExhibitor(exhibitorId)),
		onSetActiveExhibitorId: (exhibitorId) =>
			dispatch(actions.setActiveExhibitorId(exhibitorId)),
		onUpdateUserEventRoles: (eventRoles) =>
			dispatch(actions.userEventRolesUpdated(eventRoles)),
		onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
		onSetEnableMatching: (data) => dispatch(actions.setEnableMatching(data)),
		onGetExhibitors: (eventId) => dispatch(actions.getExhibitors(eventId)),
		onGetUserEventProfile: (eventId, userId) =>
			dispatch(actions.getUserEventProfile(eventId, userId)),
		onReloadUserProfile: () => dispatch(actions.triggerReloadUserProfile()),
		listFloors: (eventId) => dispatch(actions.listFloors(eventId)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EventMenu)
);
