import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA4 from 'react-ga4';
import CustomizedSpinner from '../../SmallLayoutComponents/CustomizedSpinner';
import * as actions from '../../store/actions/index';
import Menu from '../../Components/Menu';
import ScrollToTop from '../../Components/ScrollToTop';
import EventPublicRoute from './EventPublicRoute';
import EventPrivateRoute from './EventPrivateRoute';
import ParticipantRoute from './ParticipantRoute';
import ExhibitorRoute from './ExhibitorRoute';
import ExhibitorRepRoute from './ExhibitorRepRoute';
import OrganizerRoute from './OrganizerRoute';
import PrivateRoute from './PrivateRoute';
import SuperAdminRoute from './SuperAdminRoute';

import RegisterEvent from '../../Pages/Event/RegisterEvent';
import RegisterAsParticipant from '../../Pages/Event/RegisterAsParticipant';
import RegisterAsExhibitor from '../../Pages/Event/RegisterAsExhibitor';
import RegisterAsScholar from '../../Pages/Event/RegisterAsScholar';

import LoginPlatform from '../../Pages/LoginPlatform';
import RegisterPlatform from '../../Pages/RegisterPlatform';
import HomepagePlatform from '../../Pages/HomepagePlatform';
import PasswordRecovery from '../../Pages/PasswordRecovery';
import NewsletterUnsubscribe from '../../Pages/NewsletterUnsubscribe';
import CreateEvent from '../../Pages/CreateEvent';
import HomepageEvent from '../../Pages/Event/HomepageEvent';
import Auditorium from '../../Pages/Auditorium/Auditorium';
import AuditoriumArhive from '../../Pages/Event/AuditoriumArhive';
import AuditoriumArhiveSingleVideo from '../../Pages/Event/AuditoriumArhiveSingleVideo';
import MyAccount from '../../Pages/MyAccount/MyAccount';
import NotFound from '../../Pages/NotFound';
import Lobby from '../../Pages/Event/Lobby';
import Metaverse from '../../Pages/Event/Metaverse';
import FloorPlan from '../../Pages/Event/FloorPlan';

import Showfloor from '../../Pages/Showfloor/Showfloor';

import Village from '../../Pages/Event/Village';
import BuildingLobby from '../../Pages/Building/BuildingLobby';
import BuildingAuditorium from '../../Pages/Building/BuildingAuditorium';
import BuildingShowfloor from '../../Pages/Building/BuildingShowfloor';
import BuildingAuditoriumArchive from '../../Pages/Building/BuildingAuditoriumArchive';
import BuildingAuditoriumArchiveSingleVideo from '../../Pages/Building/BuildingAuditoriumArchiveSingleVideo';

import PrivacyPolicy from '../../Pages/GDPR/PrivacyPolicy';
import Cookies from '../../Pages/GDPR/Cookies';
import TermsAndConditions from '../../Pages/GDPR/TermsAndConditions';
import EventArchive from '../../Pages/Event/EventArchive';
import EventArchiveVideo from '../../Pages/Event/EventArchiveVideo';
import EventPrivacyPolicy from '../../Pages/Event/EventPrivacyPolicy';
import EventTermsAndConditions from '../../Pages/Event/EventTermsAndConditions';
import SingleLoginEventPage from '../../Pages/Event/SingleLoginEventPage';
import AllEvents from '../../Pages/Admin/AllEvents/AllEvents';
import AdminEventStatistics from '../../Pages/Admin/AllEvents/SingleEvent.js';
import AdminStatistics from '../../Pages/Admin/AdminStatistics/AdminStatistics';

import SessionDashboard from '../../Pages/SessionDashboard';
import LiveSession from '../../Pages/LiveSession/LiveSession';
import LiveSessionRegistration from '../../Pages/LiveSession/LiveSessionRegistration';
import ZoomSessionDashboard from '../../Pages/ZoomSessionDashboard/ZoomSessionDashboard';
import SuccessIframeRegistration from '../../Pages/SuccessIframeRegistration/SuccessIframeRegistration';
import EventsToday from '../../Pages/Admin/EventsToday/EventsToday';

const ExhibitorDashboard = lazy(
	() => import('../../Pages/Event/ExhibitorDashboard')
);
const ExhibitorBoothView = lazy(
	() => import('../../Pages/Event/ExhibitorBoothView')
);
const ExhibitorFullPreview = lazy(
	() => import('../../Components/BoothManagement/BoothFullPreview')
);

const EventBuildings = lazy(
	() => import('../../Components/EventManagement/EventBuildings')
);
const EventProgramDashboard = lazy(
	() => import('../../Components/EventManagement/EventProgramDashboard')
);
const EventProgramDashboardNew = lazy(
	() => import('../../Components/EventManagement/EventProgramDashboard.new')
);
const EventRoomsDashboard = lazy(
	() => import('../../Components/EventManagement/EventRoomsDashboard')
);
const EventAuditoriumsDashboard = lazy(
	() => import('../../Components/EventManagement/EventAuditoriums.new')
);
const EventShowfloorDashboard = lazy(
	() => import('../../Components/EventManagement/EventShowfloorDashboard')
);
const EventSettings = lazy(
	() => import('../../Components/EventManagement/EventSettings')
);
const EventBranding = lazy(
	() => import('../../Components/EventManagement/EventBranding')
);
const EventStatistics = lazy(
	() => import('../../Components/EventManagement/EventStatistics')
);
const EventNewsletters = lazy(
	() => import('../../Components/EventManagement/EventNewsletters')
);
const EventUsersDashboard = lazy(
	() => import('../../Components/EventManagement/UsersDashboard')
);
const ArchivedEvents = lazy(
	() => import('../../Components/EventManagement/ArchivedEvents')
);
const EventSessions = lazy(
	() => import('../../Components/EventManagement/EventSessions/EventSessions')
);

const EventGDPR = lazy(
	() => import('../../Components/EventManagement/EventGDPR')
);
const AddEditAudience = lazy(
	() =>
		import(
			'../../Components/EventManagement/Newsletters/Audiences/SingleAudience/EditAudience'
		)
);
const EventPayment = lazy(
	() => import('../../Components/EventManagement/Payment/EventPayment.js')
);

const Dashboard = lazy(() => import('../../Pages/Admin/Dashboard'));
const OrganizerDetails = lazy(
	() => import('../../Pages/Admin/OrganizerDetails')
);
const MasterAdminGDPRPlatform = lazy(
	() => import('../../Pages/Admin/GDPR/GDPRPlatform')
);
const MasterAdminGDPREvent = lazy(
	() => import('../../Pages/Admin/GDPR/GDPREvent')
);
const MasterAdminLegal = lazy(() => import('../../Pages/Admin/Legal/Legal'));

const history = createBrowserHistory();

class Routes extends Component {
	componentDidMount() {
		window.addEventListener(
			'CookiebotOnConsentReady',
			function (e) {
				if (e?.currentTarget?.CookieConsent?.consent?.statistics) {
					if (window.location.href.search('aifg.onvent.online') !== -1) {
						ReactGA4.initialize('G-Z982589QYG');
						ReactGA4.send({ hitType: 'pageview', page: window.location.href });
					}
					if (
						window.location.href.search('neuralnetwork.aiforgood.itu.int') !==
						-1
					) {
						ReactGA4.initialize('G-ZCCX0P3JGV');
						ReactGA4.send({ hitType: 'pageview', page: window.location.href });
					}
				}
			},
			false
		);
		this.props.onAutoLoginWithToken();
		this.unlisten = history.listen((location, action) => {
			const { eventSlug } = this.props;
			const urlEventSlug = location.pathname
				.replace('/event/', '')
				.split('/')[0];

			if (eventSlug !== urlEventSlug) {
				this.props.setEventSlug(urlEventSlug);
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	render() {
		const { user, event, eventSlug } = this.props;
		const userRolesInEvent = user?.eventRoles.find(
			(role) => role.event.slug === eventSlug
		);
		return (
			<BrowserRouter history={history}>
				<Menu />
				<ScrollToTop />
				<Suspense fallback={<CustomizedSpinner />}>
					<Switch>
						<Route path="/login" component={LoginPlatform} exact />
						<Route path="/register" component={RegisterPlatform} exact />
						<PrivateRoute path="/create-event" component={CreateEvent} exact />

						<Route
							path="/password-recovery"
							component={PasswordRecovery}
							exact
						/>
						<Route
							path="/unsubscribe"
							component={NewsletterUnsubscribe}
							exact
						/>
						<Route path="/privacy-policy" component={PrivacyPolicy} exact />
						<Route path="/cookies" component={Cookies} exact />
						<Route
							path="/terms-and-conditions"
							component={TermsAndConditions}
							exact
						/>
						<EventPublicRoute
							path="/event/:eventSlug/privacy-policy"
							component={PrivacyPolicy}
							exact
						/>
						<EventPublicRoute
							path="/event/:eventSlug/cookies"
							component={Cookies}
							exact
						/>
						<EventPublicRoute
							path="/event/:eventSlug/terms-and-conditions"
							component={TermsAndConditions}
							exact
						/>

						<EventPublicRoute
							path="/event/:eventSlug"
							component={HomepageEvent}
							exact
						/>
						{/* we don't have a Login page anymore, socialLogin is now redirected to /register pages*/}
						<EventPublicRoute
							path="/event/:eventSlug/login"
							component={SingleLoginEventPage}
							exact
						/>
						<EventPublicRoute
							path="/event/:eventSlug/register"
							component={RegisterEvent}
							exact
						/>
						<EventPublicRoute
							path="/event/:eventSlug/event-privacy-policy"
							component={EventPrivacyPolicy}
							exact
						/>
						<EventPublicRoute
							path="/event/:eventSlug/event-terms-and-conditions"
							component={EventTermsAndConditions}
							exact
						/>

						<EventPublicRoute
							path="/event/:eventSlug/iframe-registration"
							component={SuccessIframeRegistration}
							exact
						/>

						<EventPrivateRoute
							path="/event/:eventSlug/participant-registration"
							component={RegisterAsParticipant}
							exact
						/>
						<EventPrivateRoute
							path="/event/:eventSlug/exhibitor-registration"
							component={RegisterAsExhibitor}
							exact
						/>
						<EventPrivateRoute
							path="/event/:eventSlug/scholar-registration"
							component={RegisterAsScholar}
							exact
						/>

						<ParticipantRoute
							path="/event/:eventSlug/lobby"
							component={Lobby}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/showfloor"
							component={Showfloor}
							exact
						/>
						{/* <ParticipantRoute path="/event/:eventSlug/metaverse" component={Metaverse} exact /> */}
						<ParticipantRoute
							path="/event/:eventSlug/floorplan"
							component={FloorPlan}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/auditorium/:auditoriumIndex?"
							component={Auditorium}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/auditorium-archive"
							component={AuditoriumArhive}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/auditorium-archive/:auditoriumId/timeslot/:timeslotId"
							component={AuditoriumArhiveSingleVideo}
							exact
						/>

						<ParticipantRoute
							path="/event/:eventSlug/event-archive"
							component={EventArchive}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/event-archive/event/:archivedEventId/:videoId"
							component={EventArchiveVideo}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/village"
							component={Village}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/village/building/:buildingId/lobby"
							component={BuildingLobby}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/village/building/:buildingId/auditorium"
							component={BuildingAuditorium}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/village/building/:buildingId/showfloor"
							component={BuildingShowfloor}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/village/building/:buildingId/auditorium-archive"
							component={BuildingAuditoriumArchive}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/village/building/:buildingId/auditorium-archive/timeslot/:timeslotId"
							component={BuildingAuditoriumArchiveSingleVideo}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/my-account"
							component={MyAccount}
							exact
						/>

						<ExhibitorRoute
							path="/event/:eventSlug/exhibitor-dashboard"
							component={ExhibitorDashboard}
							exact
						/>
						<ExhibitorRepRoute
							path="/event/:eventSlug/exhibitor-booth-view"
							component={ExhibitorBoothView}
							exact
						/>

						<ExhibitorRepRoute
							path="/event/:eventSlug/exhibitor-booth-view/:exhibitorId"
							component={ExhibitorBoothView}
							exact
						/>

						<ExhibitorRepRoute
							path="/event/:eventSlug/exhibitor-booth-preview"
							component={ExhibitorFullPreview}
							exact
						/>

						<OrganizerRoute
							path="/event/:eventSlug/dashboard/auditoriums"
							component={EventAuditoriumsDashboard}
							exact
							hasAccess={userRolesInEvent?.accessRights.auditoriums}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/program"
							component={EventProgramDashboardNew}
							exact
							hasAccess={userRolesInEvent?.accessRights.program}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/buildings"
							component={EventBuildings}
							exact
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/showfloor"
							component={EventShowfloorDashboard}
							exact
							hasAccess={userRolesInEvent?.accessRights.showfloors}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/settings"
							component={EventSettings}
							exact
							hasAccess={userRolesInEvent?.accessRights.setup}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/branding"
							component={EventBranding}
							exact
							hasAccess={userRolesInEvent?.accessRights.branding}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/statistics"
							component={EventStatistics}
							exact
							hasAccess={userRolesInEvent?.accessRights.statistics}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/participants"
							component={EventUsersDashboard}
							exact
							hasAccess={userRolesInEvent?.accessRights.users}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/archived-events"
							component={ArchivedEvents}
							exact
							hasAccess={userRolesInEvent?.accessRights.archive}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/event-sessions"
							component={EventSessions}
							exact
							hasAccess={
								userRolesInEvent?.accessRights.sessions &&
								event?.sessionsStreaming
							}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/rtmp-session/:sessionId"
							component={ZoomSessionDashboard}
							exact
						/>
						<ParticipantRoute
							path="/event/:eventSlug/live-session/:sessionId/dashboard"
							component={LiveSession}
							exact
						/>

						<Route
							strict
							path="/event/:eventSlug/live-session/:sessionId"
							component={LiveSession}
							exact
						/>
						<Route
							strict
							path="/event/:eventSlug/live-session/:sessionId/registration/:role"
							component={LiveSessionRegistration}
							exact
						/>

						<Route
							strict
							path="/event/:eventSlug/session/:sessionId/:invitation?"
							component={SessionDashboard}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/newsletters"
							component={EventNewsletters}
							exact
							hasAccess={userRolesInEvent?.accessRights.newsletters}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/newsletters/edit-audience/:audienceId"
							component={AddEditAudience}
							exact
							hasAccess={userRolesInEvent?.accessRights.newsletters}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/event-gdpr"
							component={EventGDPR}
							exact
							hasAccess={userRolesInEvent?.accessRights.gdpr}
						/>
						<OrganizerRoute
							path="/event/:eventSlug/dashboard/event-payment"
							component={EventPayment}
							exact
							hasAccess={userRolesInEvent?.accessRights.accessManagement}
						/>

						<SuperAdminRoute
							path="/dashboard/organizers"
							component={Dashboard}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/organizers/statistics"
							component={AdminStatistics}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/organizers/all-events"
							component={AllEvents}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/organizers/events/:eventId"
							component={AdminEventStatistics}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/organizers/events-today"
							component={EventsToday}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/organizers/all-events/page/:page"
							component={AllEvents}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/organizer/:organizerId"
							component={OrganizerDetails}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/gdpr-platform"
							component={MasterAdminGDPRPlatform}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/gdpr-event"
							component={MasterAdminGDPREvent}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/legal/"
							component={MasterAdminLegal}
							exact
						/>
						<SuperAdminRoute
							path="/dashboard/legal/:pageNumber"
							component={MasterAdminLegal}
							exact
						/>
						<Route path="/" component={HomepagePlatform} exact />
						<Route path="*" component={NotFound} />
					</Switch>
				</Suspense>
			</BrowserRouter>
		);
	}
}
const mapStateToProps = (state) => ({
	user: state.user.data,
	event: state.event.data,
	loggedIn: state.user.loggedIn,
	eventSlug: state.event.eventSlug,
});
const mapDispatchToProps = (dispatch) => {
	return {
		onAutoLoginWithToken: () => dispatch(actions.autoLoginWithToken()),
		setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
